import IconLink from "./components/IconLink";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="elysium-logo.png" className="Elysium-logo" alt="logo" />
        <p>
          Coming Soon<sup className="tm">™</sup>
        </p>
        <div className="icon-links">
          <IconLink link="https://discord.gg/WTNMsaTE3r" icon="discord-logo.png" />
          <IconLink link="https://twitter.com/ElysiumEntPR" icon="twitter-logo.png" />
          <IconLink link="https://ko-fi.com/elysiumenterprises" icon="kofi-logo.png" />
        </div>
      </header>
    </div>
  );
}

export default App;
