
function IconLink(props) {
  return (
    <a
      className="icon-link"
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={props.icon} alt={props.alt || "icon"}></img>
    </a>
  );
}

export default IconLink;
